//Error Messages
export const ACCOUNT_NUMBER_VALIDATION_ERROR = "Account Number is required";
export const ACCOUNT_NUMBER_LENGTH_VALIDATION_ERROR = "Bank Account Number must be between 5 and 17 digits";
export const COMMON_API_ERROR = "Failed to fetch data";

export const UI_ERR_BNK_REQ_FIELD = "UI_ERR_BNK_REQ_FIELD";
export const UI_ERR_BNK_MIN_LENGTH = "UI_ERR_BNK_MIN_LENGTH";
export const API_ERR_BNK = "API_ERR_BNK";
export const API_ERR_BNK_SYS_ERROR = "API_ERR_BNK_SYS_ERROR";


//Brand Values
export const BRAND_RE = "RE";
export const BRAND_CE = "CE";
export const BRAND_DP = "DP";
export const BRAND_GME_RES = "GME_RES";
export const BRAND_GME_CNI = "GME_CNI";
export const BRAND_GME_RES_1 = "GME_RES_1";
