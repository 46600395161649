import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './index.css';
import { GET_BANK_TOKEN, GET_AUTH_TOKEN } from '../util/api';
import { BRAND_CE, BRAND_DP, BRAND_GME_CNI, BRAND_GME_RES, BRAND_RE, BRAND_GME_RES_1, UI_ERR_BNK_MIN_LENGTH, 
  UI_ERR_BNK_REQ_FIELD, API_ERR_BNK, API_ERR_BNK_SYS_ERROR
 } from '../util/constant';
import { importKey, encryptMessage } from '../util/common';

const IndexPage = () => {
  const [accNo, setAccNo] = useState('');
  const inputRef = useRef();
  const [selectedBrand, setSelectedBrand] = useState('');
  const [brandName, setBrandName] = useState('');
  const [mobView, setMobView] = useState(false);

  const accNoMax = "17";
  const path = `${process.env.GATSBY_PCI_API_URL}` ? `${process.env.GATSBY_PCI_API_URL}` : process.env.GATSBY_PCI_API_URL;
  const apiKey = `${process.env.GATSBY_X_API_KEY}` ? `${process.env.GATSBY_X_API_KEY}` : process.env.GATSBY_X_API_KEY;
  const awsKey = `${process.env.GATSBY_PUB_KEY}` ? `${process.env.GATSBY_PUB_KEY}` : process.env.GATSBY_PUB_KEY;
  const isWindow = typeof window !== "undefined";

  if (isWindow) {
    window.onmessage = function (e) {
      if (e.data === 'msg') {
        onInputEvent(e);
      } else if (e.data === 'default') {
        resetValues();
      } else if (e.data && e.data.brandId) {
        let id = e.data.brandId.toUpperCase();
        getBrandId(id);
      } else if (e.data && e.data.mobView) {
        setMobView(e.data.mobView);
        rWebView();
      } else if (e.data && e.data.deskView) {
        setMobView(false);
        rWebView();
      }
    };

  }

  useEffect(() => {
    rWebView();
  }, [brandName, mobView])



  useEffect(() => {
    removeValidationCheck();
  }, [accNo])

  // Testing in local
  const init = () => {
    window.top.postMessage({ brandId: 'GME_CNI' }, "*");

  }

  const authHaders = {
    'x-api-key': apiKey
  }

  const tokenHeaders = {
    'Accept': '*/*',
    'Content-Type': 'application/json'
  }

  const rWebView = () => {
    if (brandName === BRAND_RE) {
      if (mobView) {
        inputRef.current.classList.add('rel-input-responsive');
      } else {
        inputRef.current.classList.remove('rel-input-responsive');
      }
    } else if (brandName === BRAND_GME_RES) {
      if (mobView) {
        inputRef.current.classList.add('gme-res-input-responsive');
      } else {
        inputRef.current.classList.remove('gme-res-input-responsive');
      }
    } else if (brandName === BRAND_GME_RES_1) {
      if (mobView) {
        inputRef.current.classList.add('gme-res-1-input-responsive');
      } else {
        inputRef.current.classList.remove('gme-res-1-input-responsive');
      }
    }
  }

  const getBrandId = (id) => {
    setBrandName(id);
    if (id === BRAND_RE) {
      setSelectedBrand('rel-input');
    } else if (id === BRAND_CE) {
      setSelectedBrand('cirro-input');
    } else if (id === BRAND_GME_RES) {
      setSelectedBrand('gme-res-input');
    } else if (id === BRAND_GME_RES_1) {
      setSelectedBrand('gme-res-1-input');
    } else if (id === BRAND_GME_CNI) {
      setSelectedBrand('gme-cni-input');
    } else if (id === BRAND_DP) {
      setSelectedBrand('dep-input');
    } else {
      setSelectedBrand('rel-input');
    }
  }

  const getAuthToken = (e) => {
    if (accNo !== "") {
      // setSpecificErrorMsg('');
      fetch(path + GET_AUTH_TOKEN, {
        method: 'POST',
        headers: authHaders
      })
        .then(response => response.json())
        .then(data => {
          if (data && data.token) {
            getAWSKey(e, data.token);
            // onSubmitCall(e, data.token);
          } else if (data && data.message) {
            window.top.postMessage(API_ERR_BNK, "*");
          }
        })

        .catch(err => {
          window.top.postMessage(API_ERR_BNK_SYS_ERROR, "*");
          console.log(err);
        });
    }

  }

  const resetValues = () => {
    setAccNo("");
    inputRef.current.classList.remove('input-elm--border');
  }

  const validateInputAccountNo = (e) => {
    var inputVal = e.target.value.replace(/\D/g, "");
    setAccNo(DOMPurify.sanitize(inputVal));
  }

  const checkBrandValidations = (e) => {
    if(brandName=== BRAND_GME_CNI) {
      onInputEvent(e);
    }
  }

  const removeValidationCheck = () => {
    if((accNo && accNo.length >= 5) && brandName === BRAND_GME_CNI) {
      window.top.postMessage('REMOVE_VALIDATION_MSG', '*');
    }
  }

  const onInputEvent = (e) => {
    e.preventDefault();
    if (accNo === "") {
      window.top.postMessage(UI_ERR_BNK_REQ_FIELD, "*");
      if(brandName !== BRAND_GME_CNI) {
        inputRef.current.classList.add('input-elm--border');
      }
    } else if (accNo.length < 5) {
      window.top.postMessage(UI_ERR_BNK_MIN_LENGTH, "*")
      inputRef.current.classList.add('input-elm--border');
      if(brandName !== BRAND_GME_CNI) {
        inputRef.current.classList.add('input-elm--border');
      }
    } else {
      inputRef.current.classList.remove('input-elm--border');
      getAuthToken(e);
    }
  }


  const getAWSKey = (e, token) => {
    let key = awsKey;
    if (key) {
      importKey(key).then((val) => {
        encryptMessage(val, accNo).then((data) => {
          let base64String = btoa(String.fromCharCode(...new Uint8Array(data)));
          if (base64String && base64String.length > 0) {
            onSubmitCall(e, base64String, token);
          }
        });
      },
        (error) => {
          console.error(error); // Error!
        },)
    }
  }


  const onSubmitCall = (e, bankAccNo, token) => {
    e.preventDefault();
    if (accNo !== "") {
      let reqParams = { "bankAccountNumber": bankAccNo, "companyCode": "0121" };
      tokenHeaders['Authorization'] = "Bearer " + token;
      fetch(path + GET_BANK_TOKEN, {
        method: 'POST',
        headers: tokenHeaders,
        body: JSON.stringify(reqParams)
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            window.top.postMessage(API_ERR_BNK, "*");
          } else if (data.errorCode !== "" || data.errorMessage !== "") {
            window.top.postMessage(API_ERR_BNK, "*");
          } else {
            if (isWindow) {
              window.top.postMessage({ 'token': data.tokenizedBankAccountNumber }, "*") //* should be replaced with domain name/origin name
            }
          }
        })

        .catch(err => {
          window.top.postMessage(API_ERR_BNK_SYS_ERROR, "*");
          console.log(err);
        });
    }
  }


  return (
    <div className='main'>
      <div><input onInput={validateInputAccountNo} onBlur={checkBrandValidations} autoComplete='off' ref={inputRef} maxLength={accNoMax} value={accNo} className={selectedBrand} name="accountNum" type="text" id="InputAccNum" /></div>
    </div>
  )
}

export default IndexPage
