const getMessageEncoding = (accNo) => {
    let textEnc = new TextEncoder();
    return textEnc.encode(accNo);
  }
  
 export const encryptMessage = (publicKey, accNo) => {
    let encoded = getMessageEncoding(accNo);
    return window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      encoded,
    );
  }


  
  function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  export function importKey(pemKey) {
    const binaryString = atob(pemKey);
    const arrayBuff = str2ab(binaryString);
    return window.crypto.subtle.importKey(
      "spki",
      arrayBuff,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"],
    );
  }
